/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { CustomModal } from "./Modal";
import { Button } from "antd";
import { useGame } from "../contexts";
import { BoxContainer } from "./Common";
import { useAuth } from "../contexts/auth";
import { GameStates } from "../utility/game";
import { DataClass } from "../utility";

export const DiesExpiredBox = ({ setTurnExpired, restartTimer, roll, pay }) => {
  const [clearTimeout, setClearTimeout] = useState(false);
  const { turnData, currentPlayer, players, nextTurn, gameState, room,updatePlayer} = useGame();

  const { user } = useAuth();

  const { currentGamePlayers } = useMemo(
    () => ({
      loggedInPlayer: players.find((x) => x.id === user?.uid),
      currentGamePlayers: players.filter((x) => !x.lose && !x.left),
    }),
    [players, user],
  );  



  const turnDies = async () => {
    if (!clearTimeout) {
      let playerCash = currentPlayer.cash
      let payCost = 0
      
      if (gameState === GameStates.doodad || gameState === GameStates.ftDoodad) {
        payCost = room?.doodad?.cost
        if(gameState === GameStates.ftDoodad){
          payCost = room?.ftBox?.cost
        }
        if (room?.doodad?.amount) {
          payCost = playerCash * room?.doodad?.amount;
        }
        playerCash = playerCash - payCost
      }
      else if( gameState === GameStates.downsized){      
        payCost = turnData?.cost  || currentPlayer?.totalExpenses
        playerCash = playerCash -  payCost
      }
      else if( gameState === GameStates.charity){
        payCost = currentPlayer.totalIncome * 0.1
        playerCash = playerCash -  payCost
      }
      else if(gameState === GameStates.baby){
        if (currentPlayer?.children > 2) {
          payCost =  0;
        }
        payCost =  Math.floor(currentPlayer.totalIncome * 0.056);
        playerCash = playerCash -  payCost
      }      
      const cost =  payCost + 
        (playerCash * parseInt(room?.timerCashPanelty)) /
        100;    
     
      if(currentPlayer?.dream){   
        pay({
          name: "DiesExpired",
          cost: Math.floor(cost),
        });   
        await nextTurn();
      }
      else if(!currentPlayer?.dream){
        await updatePlayer({ cash: currentPlayer.cash - Math.floor(cost),dream: DataClass.dreams[0]?.text})
      }
      setTurnExpired(false);
      restartTimer();
      setClearTimeout(true);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      turnDies();
    }, "5000");
  }, [clearTimeout]);

  return (
    <CustomModal>
      <BoxContainer>
        <div>
          <h2>{currentPlayer?.name}'s Turn expired</h2>
          <p>
            {room?.timerCashPanelty}% cash will be deducted and roll will turn to next{" "}
            {currentGamePlayers.length > 1 ? "user" : "die"}
          </p>
          <Button type="primary" block onClick={turnDies}>
            Ok
          </Button>
        </div>
      </BoxContainer>
    </CustomModal>
  );
};
