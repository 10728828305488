/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useCallback } from "react";
import  _debounce from "lodash/debounce";
import { useTimer } from "react-timer-hook";
import { useGame } from "../contexts";
import {
  BabyBox,
  BankruptBox,
  CashflowBox,
  CharityBox,
  ChooseDreamBox,
  DealBox,
  DealOfferBox,
  DoodadBox,
  DownsizedBox,
  EnterFastTrackBox,
  FastTrackDoodadBox,
  FastTrackStatement,
  FinishTurnBox,
  GameoverBox,
  LoseBox,
  MarketBox,
  OpportunityBox,
  OutOfRatRaceBox,
  OuterBox,
  RepayBox,
  Statement,
  StatementHeader,
  TakeALoanBox,
  TurnBox,
} from "../components";
import { GameStates } from "../utility/game";
import { Drawer } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/auth";
import { playAudio,  } from "../utility";
import { AudioConstant } from "../audio";
import { WinnerBox } from "../components/WinnerBox";
import { DiesExpiredBox } from "../components/DiesExpiredBox";
import { TimerBox } from "../components/TimerBox";


const StatementContainer = styled.div`
  width: 20px;
  height: 220px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  span {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
`;



export const GameScreen = () => {
  const { user } = useAuth();
  const [turnExpired, setTurnExpired] = useState(false);

  const { id } = useParams();
  const {
    setRoomId,
    gameState,
    movePlayer,
    payExpenses,
    buyDeal,
    sellDeal,
    openDrawer,
    setOpenDrawer,
    finishTurn,
    dealDecision,
    currentPlayer,
    players,
    allOut,
    leaveTheLobby,
    yourTurn,
    updatePlayer,
    updateRoom,
    room
  } = useGame();

  const loggedInPlayer = useMemo(
    () => players.find((x) => x.id === user?.uid),
    [players, user,updatePlayer]
  );

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: async () => {
      if (yourTurn) {
        setTurnExpired(true);
      }
    },
  });

  const restartTimer = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + + room?.timerInSecond);
    restart(time, true);
  };

  useEffect(() => {
    setRoomId(id);
    if (gameState === GameStates.turn) {
      restartTimer();
    }
  }, [gameState]);

  useEffect(() => {
    setTurnExpired(false);
    restartTimer();
  }, [yourTurn]);
  

  const payDebounce = useCallback((gameState, expense) => {  
    if(gameState){
      payExpenses(gameState, expense)
    }
  },[gameState])
  const payExpensesClick = useMemo(()=>{
    return _debounce(payDebounce,400)}
  ,[payDebounce])

  const toggleAudio = async(muteAudio) => {         
      await updatePlayer({ muteAudio}, loggedInPlayer);      
  }

  useEffect(()=>{    
     const setEnterFastTrack  = async (gameState, playerProp) => {
        await updateRoom({
          gameState,
          turnData: null,
          deal: null,
          offer: null,
          doodad: null,
        });   
        await updatePlayer(playerProp);            
     }
     if (gameState === GameStates.turn &&  yourTurn && !loggedInPlayer?.fastTrack && loggedInPlayer?.passiveIncome > currentPlayer?.totalExpenses) {
        setEnterFastTrack(GameStates.outOfRatRace,{
          fastTrack: true,
      })
    }
    if (gameState === GameStates.turn && yourTurn && loggedInPlayer?.fastTrack && loggedInPlayer?.passiveIncome > currentPlayer?.winGoal){
        setEnterFastTrack(GameStates.winner,{
          winner: true
        })
    }
    
  },[gameState]) 

  return (
    <>     
      <OuterBox players={players} />
      <StatementContainer
        onClick={() => {
          if (!openDrawer) {
            playAudio(AudioConstant.statementOpen, loggedInPlayer);
          }
          setOpenDrawer(!openDrawer);
        }}
      >
        <span>Statement</span>
      </StatementContainer>
      <Drawer
        placement="left"
        className="StatmentDrawer"
        onClose={() => {
          playAudio(AudioConstant.statementClose, loggedInPlayer);
          setOpenDrawer(false);
        }}
        open={openDrawer}
        title={
          <StatementHeader
            leaveTheLobby={leaveTheLobby}
            loggedInPlayer={loggedInPlayer}
            toggleAudio={toggleAudio}
            isMute={loggedInPlayer?.muteAudio}
          />
        }
      >
        {loggedInPlayer?.fastTrack ? (
          <FastTrackStatement loggedInPlayer={loggedInPlayer} />
        ) : (
          <Statement loggedInPlayer={loggedInPlayer} />
        )}
      </Drawer>

      {allOut && players.length > 1 ? (
        <GameoverBox />
      ) : (
        <>
          {!currentPlayer?.dream ? (
            <ChooseDreamBox />
          ) : (
            <>
              {turnExpired === false && (
                <>
                  {gameState === GameStates.turn  && !loggedInPlayer?.lose && (
                    <TurnBox
                      seconds={seconds}
                      minutes={minutes}
                      roll={(count) => movePlayer(count)}
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.deal && (
                    <DealBox deal={dealDecision} />
                  )}
                  {gameState === GameStates.doodad && (
                    <DoodadBox
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.finishTurn && <FinishTurnBox />}
                  {gameState === GameStates.downsized && (
                    <DownsizedBox
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.charity && (
                    <CharityBox
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.baby && (
                    <BabyBox
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.market && (
                    <MarketBox done={finishTurn} />
                  )}
                  {[GameStates.bigDeal, GameStates.smallDeal].includes(
                    gameState
                  ) && (
                    <DealOfferBox
                      buy={buyDeal}
                      sell={sellDeal}
                      done={finishTurn}
                    />
                  )}
                  {gameState === GameStates.opportunity && <OpportunityBox />}
                  {gameState === GameStates.cashflow && <CashflowBox />}
                  {gameState === GameStates.ftDoodad && (
                    <FastTrackDoodadBox
                      pay={(expense) => payExpensesClick(gameState, expense)}
                    />
                  )}
                  {gameState === GameStates.takeALoan && yourTurn && (
                        <TakeALoanBox />
                  )}
                  {gameState === GameStates.repay && <RepayBox />}
                  {gameState === GameStates.bankrupt && <BankruptBox />}
                  {gameState === GameStates.outOfRatRace && <OutOfRatRaceBox />}
                  {gameState === GameStates.enterFastTrack && (
                    <EnterFastTrackBox />
                  )}
                  {gameState === GameStates.winner && <WinnerBox />}
                </>
              )}
              {(gameState === GameStates.lose  || loggedInPlayer?.lose === true) && <LoseBox loggedInPlayer={loggedInPlayer}/>}
                             
            </>
          )}
          {turnExpired && (
                <DiesExpiredBox
                  pay={(expense) => payExpensesClick(gameState, expense)}
                  setTurnExpired={setTurnExpired}
                  restartTimer={restartTimer}
                />
              )}
              {yourTurn && loggedInPlayer?.lose !== true && !loggedInPlayer?.winner && (
                <TimerBox seconds={seconds} minutes={minutes}>
                  {" "}
                </TimerBox>
            )}
        </>
      )}
    </>
  );
};
